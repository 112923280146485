export const EmptyState = () => {
  return (
    <div className="flex flex-col justify-center items-center flex-grow text-neutral-800 py-4 px-8">
   
      <h3 className="text-lg font-medium mt-10">
        Zeiterfassungs App DEMO
      </h3>
      <p className="mt-2 text-center">
        Select your Activity
      </p>
    </div>
  );
};


