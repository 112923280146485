import { TimeEntriesList } from "./TimeEntriesList";
import { TopBar } from "./TopBar";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectCurrentTimeEntry, TimeEntry, timeEntryAdded, timeEntryCreatedManually, timeEntryStopped } from "./store";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import StopIcon from '@mui/icons-material/Stop';
import { useEffect, useRef, useState } from "react";
import { formatElapsedTime } from "../../utils";
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { TimeEntryCreate } from "./components/TimeEntryEdit/TimeEntryCreate";


export const TimeEntries = () => {
  const dispatch = useAppDispatch();

  const handleAddClick_Presence = () => {
    //dispatch(timeEntryCreatedManually({ text: selectedEntryType, startTime: Date.now(), endTime: Date.now() }));
    dispatch(timeEntryAdded({ text: selectedEntryType, startTime: Date.now()}));
    setselectedEntryDisabled(true);
  };

  const handleAddNew = () => {    
    setCreateFormVisible(true);
    setselectedEntryDisabled(true);
  };
  
  const currentTimeEntry = useAppSelector(selectCurrentTimeEntry);
  const handleOnStopClick = () => {
    if(currentTimeEntry){
      dispatch(timeEntryStopped(currentTimeEntry.id));
    }

    setselectedEntryDisabled(false);
  };

  const elapsedTime = useElapsedTimeForEntry(currentTimeEntry as TimeEntry);

  const [selectedEntryType, setSelectedEntryText] = useState<string>("Anwesenheit");
  const [selectedEntryDisabled, setselectedEntryDisabled] = useState<boolean>(false);
  const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);

  const selectedEntryTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();

    setSelectedEntryText(event.target.value);
  };


  return (
    <>
      {/* <TopBar /> */}
      <div style={{display: 'flex'}}>
        <div style={{flexGrow: 1, marginTop: "50px"}} className="select-wrapper fa fa-angle-down">
          <select disabled={selectedEntryDisabled} value={selectedEntryType} onChange={selectedEntryTypeChange} >
            <option>Anwesenheit</option>
            <option>Pause</option>
            <option>Auswärtstermin</option>
            <option>Urlaub</option>
            <option>Arzt</option>
            <option>Krankheit</option>
          </select>
        </div>

        <div style={{marginLeft: "32px"}}>
          {currentTimeEntry ? (
              <div className={"button-container"}>
                <div className="inner1">
                  <div className="inner2"><button onClick={handleOnStopClick} className="stop"><TimerOffIcon></TimerOffIcon><span>{currentTimeEntry.text}</span><span>{formatElapsedTime(elapsedTime)}</span></button></div>
                </div>
              </div>
          ) : (
            <div>
              <div className={"button-container"}>
                <div className="inner1">
                  <div className="inner2"><button onClick={handleAddClick_Presence} className="start"><span>START</span></button></div>
                </div>
              </div>
              {/* <Button onClick={handleAddClick_Absence} fullWidth={true} color="secondary" variant="contained" startIcon={<WorkHistoryIcon></WorkHistoryIcon>} size="large" >
                Pause
              </Button> */}

            </div>
          )}
        </div>
      </div>
      {createFormVisible ? (
        <TimeEntryCreate 
          typeText="Anwesenheit"
          setCreateFormVisible={setCreateFormVisible}
          setselectedEntryDisabled={setselectedEntryDisabled}
        ></TimeEntryCreate>
      ): <div className={"button-container-small"}>
      <div className="inner1">
        <div className="inner2"><button onClick={handleAddNew} className="addManual"><span>+</span></button></div>
      </div>
</div>}
      <TimeEntriesList />
    </>
  );
};

const useElapsedTimeForEntry = (currentTimeEntry: TimeEntry) => {
  const [elapsedTime, setElapsedTime] = useState<number>(
    currentTimeEntry ? Date.now() - currentTimeEntry.startTime : 0
  );
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setElapsedTime(
      currentTimeEntry ? Date.now() - currentTimeEntry.startTime : 0
    );
  }, [currentTimeEntry]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setElapsedTime(
        currentTimeEntry ? Date.now() - currentTimeEntry.startTime : 0
      );
    }, 500);

    return () => {
      clearInterval(intervalRef.current);
    };
  });

  return elapsedTime;
};
