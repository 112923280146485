import { TimeEntries } from "./features/TimeEntries/TimeEntries";

function App() {
  return (
    <div className="min-h-screen max-w-screen-sm flex flex-col py-4 px-2 mx-auto">
      <TimeEntries />
    </div>
  );
}

export default App;
