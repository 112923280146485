const addLeadingZeros = (num: number, totalLength: number) => {
  return String(num).padStart(totalLength, "0");
};

export const formatElapsedTime = (timeDiff: number) => {
  timeDiff = Math.floor(timeDiff / 1000);
  const seconds = Math.round(timeDiff % 60);
  timeDiff = Math.floor(timeDiff / 60);
  const minutes = Math.round(timeDiff % 60);
  timeDiff = Math.floor(timeDiff / 60);
  const hours = Math.round(timeDiff);
  return `${addLeadingZeros(hours, 2)}:${addLeadingZeros(
    minutes,
    2
  )}:${addLeadingZeros(seconds, 2)}`;
};

export const formatDayMonthYear = (datetime: number) => {
  const dateObj = new Date(datetime);

  return dateObj.toISOString().slice(0, 10);
};

export const formatDatetime = (datetime: number) => {
  const dateObj = new Date(datetime);

  return dateObj.toLocaleString();
};

export const generateId = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};


export const formatTimespan = (timeStart: number, timeEnd?: number) => {

  const start = new Date(timeStart);

  const startHours = addLeadingZeros(start.getHours(), 2);
  const startMinutes = addLeadingZeros(start.getMinutes(), 2);

  if(!timeEnd){
    return `${startHours}:${startMinutes} - ...`;  
  }

  const end = new Date(timeEnd);

  const endHours = addLeadingZeros(end.getHours(), 2);
  const endMinutes = addLeadingZeros(end.getMinutes(), 2);

  return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
};